import React from "react"
import { graphql } from 'gatsby'
import get from 'lodash/get'
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroHeader from "../components/heroheader"


class ArticleTemplate extends React.Component {
  render(data) {

  const article = get(this.props, 'data.nodeArticle');

    return (
      <Layout>
      <SEO title={article.title} />

      <HeroHeader title={article.title} summary={article.created}/>

      <Container>  
       <img width="500px" src={article.relationships.field_image.localFile.childImageSharp.fixed.src} 
        alt={article.relationships.field_image.alt} />
        <div className="body" dangerouslySetInnerHTML={{ __html: article.body.processed }} />
      </Container>

      </Layout>
    ) 
  }
}

export default ArticleTemplate

export const ArticlePageQuery = graphql`
  query PageBySlug($articleId: String!) {
    site {
      siteMetadata {
        title
      }
    }

    nodeArticle(id: { eq: $articleId }) {
      id
      title
      created(formatString: "dddd Do, MMMM YYYY")
      path {
        alias
      }
      body {
        summary
        processed
      }
      relationships {
        field_image {
          localFile {
            publicURL
            childImageSharp {
              # Specify a fixed image and fragment.
              # The default width is 400 pixels
              fixed(width: 700, height: 500) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        } 
      } 
   }
 }
 `


 const Container = styled.div`
  max-width: 1200px;
  margin: 60px 30px;

  img {
    float: none;
    margin 0 auto 20px auto;
    display: block;
  }

  @media(min-width: 1200px) {
    margin: 60px auto;
  }

  @media(min-width: 768px) {
    img {
      float: right;
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }

  

  .body {
    a {
      color: #123d5f;
    }
  }
 `